<template>
  <v-app>
    <div id="tnf-top">
    <v-navigation-drawer
      right
      v-model="drawer"
      app
      temporary
    >
      <div style="z-index: 13;" class="menus">
        <div @click.stop="drawer = !drawer" class="close-menu" style="display: block;">
            <img src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/close.svg">
        </div>
        <!--移动端菜单-->
        <div class="menus-mobile hidden-lg-only hidden-md-only hidden-xl-only">
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <h4>关于我们 <span>ABOUT US</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li><a href="/about">公司介绍</a></li>
                  <li><a href="/story">品牌故事</a></li>
                  <li><a href="/history">发展历程</a></li>
                  <li><a href="/honor">企业荣誉</a></li>
                  <li><a href="/assistant">门窗管家</a></li>
                  <li><a href="/partner">合作伙伴</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background: rgba(0,0,0,0.1) !important;">
              <v-expansion-panel-header disable-icon-rotate>
                <h4>产品中心 <span>PRODUCTS</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul v-if="info.product">
                  <li v-for="cat in info.product.category.cn" :key="cat.id"><a :href="'/products/' + cat.id">{{cat.name}}</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background: rgba(0,0,0,0.2) !important;">
              <v-expansion-panel-header disable-icon-rotate>
                <h4>案例中心 <span>CASES</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul v-if="info.cases">
                  <li v-for="cat in info.cases.category.cn" :key="cat.id"><a :href="'/cases/' + cat.id">{{cat.name}}</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background: rgba(0,0,0,0.3) !important;">
              <v-expansion-panel-header disable-icon-rotate>
                <h4>无忧服务 <span>SERVICES</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li><a href="http://hxc.trybachina.com" target="_blank">焕新窗</a></li>
                  <li><a href="/quick_change">快窗闪换</a></li>
                  <li><a href="/unify">解决方案</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background: rgba(0,0,0,0.4) !important;">
              <v-expansion-panel-header disable-icon-rotate>
                <h4>招商加盟 <span>FRANCHISE</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                    <li><a href="/join#condition">加盟条件</a></li>
                    <li><a href="/join#process">加盟流程</a></li>
                    <li><a href="/join#support">盟商支持</a></li>
                    <li><a href="/join#want">我要加盟</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background: rgba(0,0,0,0.5) !important;">
              <v-expansion-panel-header disable-icon-rotate>
                <h4>资讯动态 <span>NEWS</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                    <li><a href="/news">新闻资讯</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="background: rgba(0,0,0,0.6) !important;">
              <v-expansion-panel-header disable-icon-rotate>
                <h4>联系我们 <span>CONTACT US</span></h4>
                <template v-slot:actions> <v-icon></v-icon> </template> </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                    <li><a href="/hr">企业招聘</a></li>
                    <li><a href="/contact">联系方式</a></li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <!--PC菜单-->
        <ul class="menus-con menushide hidden-sm-and-down">
            <li class="menus-item">
                <div class="menu-child bj-1 animated1s">
                    <div class="first-container" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">关于我们</div>
                                <div class="first-entxt">ABOUT US</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">关于我们</div><div class="second-entxt">ABOUT US</div></div></dt>
                            <dd class="second-cont">
                                <h4><a href="/about">公司介绍</a></h4>
                                <h4><a href="/story">品牌故事</a></h4>
                                <h4><a href="/history">发展历程</a></h4>
                                <h4><a href="/honor">企业荣誉</a></h4>
                                <h4><a href="/assistant">门窗管家</a></h4>
                                <h4><a href="/partner">合作伙伴</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
            <li class="menus-item">
                <div class="menu-child bj-2 animated1s">
                    <div class="first-container" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">产品中心</div>
                                <div class="first-entxt">PRODUCTS</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">产品中心</div><div class="second-entxt">PRODUCTS</div></div></dt>
                            <dd class="second-cont" v-if="info.product">
                              <h4 v-for="cat in info.product.category.cn" :key="cat.id"><a :href="'/products/' + cat.id">{{cat.name}}</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
            <li class="menus-item">
                <div class="menu-child bj-3 animated1s">
                    <div class="first-container" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">案例中心</div>
                                <div class="first-entxt">CASES</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">案例中心</div><div class="second-entxt">CASES</div></div></dt>
                            <dd class="second-cont" v-if="info.cases">
                                <h4 v-for="cat in info.cases.category.cn" :key="cat.id"><a :href="'/cases/' + cat.id">{{cat.name}}</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
            <li class="menus-item">
                <div class="menu-child bj-4 animated1s">
                    <div class="first-container" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">无忧服务</div>
                                <div class="first-entxt">SERVICES</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">无忧服务</div><div class="second-entxt">SERVICES</div></div></dt>
                            <dd class="second-cont">
                                <h4><a href="http://hxc.trybachina.com" target="_blank">焕新窗</a></h4>
                                <h4><a href="/quick_change">快窗闪换</a></h4>
                                <h4><a href="/unify">解决方案</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
            <li class="menus-item">
                <div class="menu-child bj-5 animated1s">
                    <div class="first-container" style="opacity: 1;">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">招商加盟</div>
                                <div class="first-entxt">FRANCHISE</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1;">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">招商加盟</div><div class="second-entxt">FRANCHISE</div></div></dt>
                            <dd class="second-cont">
                                <h4><a href="/join#condition">加盟条件</a></h4>
                                <h4><a href="/join#process">加盟流程</a></h4>
                                <h4><a href="/join#support">盟商支持</a></h4>
                                <h4><a href="/join#want">我要加盟</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
            <li class="menus-item">
                <div class="menu-child bj-6 animated1s">
                    <div class="first-container" style="opacity: 1;">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">资讯动态</div>
                                <div class="first-entxt">NEWS</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1;">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">资讯动态</div><div class="second-entxt">NEWS</div></div></dt>
                            <dd class="second-cont">
                                <h4><a href="/news">新闻资讯</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
            <li class="menus-item">
                <div class="menu-child bj-7 animated1s">
                    <div class="first-container" style="opacity: 1;">
                        <div class="first-title" style="display: block;">
                            <div class="first-con animated1s">
                                <div class="first-name">联系我们</div>
                                <div class="first-entxt">CONTACT US</div>
                            </div>
                        </div>
                    </div>
                    <div class="second-item show-table" style="opacity: 1;">
                        <dl class="second-title table-cell"><dt class="second-con"><div class="second-content secondContentmid"><div class="second-name">联系我们</div><div class="second-entxt">CONTACT US</div></div></dt>
                            <dd class="second-cont">
                                <h4><a href="/hr">企业招聘</a></h4>
                                <h4><a href="/contact">联系方式</a></h4>
                            </dd>
                        </dl>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    </v-navigation-drawer>
    <v-app-bar app flat absolute class="app-bar">
      <v-toolbar-title class="headline" style="display: flex;align-items: center;" v-show="scrollUp">
        <a href="/"><img :src="tnfTopLogo" style="height:24px;"/></a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div>
        <v-flex style="clear: both;">
          <v-toolbar-items>
            <v-app-bar-nav-icon
              :color="barColor"
              class="tnf-top-icon"
              @click.stop="drawer = !drawer"
            >
            </v-app-bar-nav-icon>
          </v-toolbar-items>
        </v-flex>
      </div>
    </v-app-bar>
    </div>
    <v-main>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    Footer: () => import('@/components/Footer')
  },
  data: () => ({
    i: 0,
    scrollUp: true,
    keywords: '',
    afterGetData: false,
    drawer: null,
    on: false,
    languages: ['简体中文', 'English'],
    tnfTopLogo: null,
    barColor: '#FFF',
    blueLogo: ['news', 'newsDetail', 'products', 'productDetail', 'cases', 'caseDetail', 'hr', 'contact']
  }),
  mounted () {
    this.$store.dispatch('loadInfo')
    // 监听（绑定）滚轮 滚动事件
    window.addEventListener('scroll', this.handleScroll, true)
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        // this.info.logo = val.logo
        if (this.blueLogo.includes(this.$route.name) === false) {
          this.tnfTopLogo = val.logo
        }
        document.querySelector('meta[name="keywords"]').setAttribute('content', val.seo_keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', val.seo_description)
        document.querySelector('link[rel*=\'icon\']').setAttribute('href', val.favicon)
      },
      deep: true
    },
    '$route' (to, from) {
      this.title(this.$route)
      if (this.blueLogo.includes(this.$route.name) === true) {
        this.barColor = '#0C65A5'
        this.tnfTopLogo = 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/logo_blue.png'
      }
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    // 搜索
    search () {
      if (this.keyword !== '') {
        this.$router.push({ path: '/search', query: { keywords: this.keywords } })
      }
    },
    /**
     * 路由切换设置title
     * @param to
     */
    title (to) {
      // document.title = to.meta.title + '_' + this.$store.getters.companyInfo.name
    },
    // 向下滚动还是向上滚动,向下滚动则隐藏顶部logo
    handleScroll () {
      // 页面滚动距顶部距离
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var scroll = scrollTop - this.i
      this.i = scrollTop
      if (scroll < 0) {
        this.scrollUp = true
      } else {
        this.scrollUp = false
      }
    }
  }
}
</script>
<style lang="scss">
  @import 'sass/variables.scss';
  .v-application {
    font-family: $body-font-family !important;
  }
</style>
<style lang="css">
  @import 'css/public.css';
</style>
